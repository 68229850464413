import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, palette, spacing, typography } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        [`& .${classes.card}`]: {
          height: '100%',
        },
        [`& .${classes.descriptionCard}`]: {
          background: palette.background.default,
        },
        [`& .${classes.descriptionCardContent}`]: {
          '& > *:not(:last-child)': {
            marginBottom: spacing(2.5),
            [breakpoints.up('lg')]: {
              marginBottom: spacing(3),
            },
          },
        },
        [`& .${classes.diagramCard}`]: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          [breakpoints.up('sm')]: {
            '& .MuiCardHeader-content, & .MuiCardHeader-subheader': {
              marginLeft: 'auto',
              marginRight: 'auto',
            },
            '& .MuiCardHeader-content': {
              maxWidth: breakpoints.values.sm * 0.85,
            },
            '& .MuiCardHeader-subheader': {
              maxWidth: breakpoints.values.sm * 0.75,
            },
          },
        },
        [`& .${classes.diagramImage}`]: {
          marginTop: spacing(3),
          '& .MuiCardMedia-root': {
            display: 'block !important',
          },
        },
        [`& .${classes.diagramImageFixed}`]: {
          '& .MuiCardMedia-root': {
            left: '50%',
            transform: 'translateX(-50%)',
          },
        },
        [`& .${classes.header}`]: {},
        [`& .${classes.subheader}`]: {
          fontWeight: typography.fontWeightMedium,
        },
      },
    },
  };
};
