import React, { ReactNode } from 'react';
import clsx from 'clsx';
// gatsby
import { graphql, Link as GatsbyLink, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
// material
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
// components
import { BoxWithHeader, HeroSection, TextGradient, Hero } from 'components';
// hooks
import { useLocales, usePath } from 'hooks';
// types
import { ImageType } from 'types/image';
//
import { stylesRoot } from './styles';

interface HeadingProps {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  smallScreen?: boolean;
  hero?: ImageType;
}

interface HeadingAllProps extends HeadingProps {
  className?: string;
}

const PREFIX = 'Heading';
const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  text: `${PREFIX}-text`,
  decorationImg: `${PREFIX}-decorationImg`,
  lineImage: `${PREFIX}-lineImage`,
  lineLeftImage: `${PREFIX}-lineLeftImage`,
  lineRightImage: `${PREFIX}-lineRightImage`,
  coin: `${PREFIX}-coin`,
  coinLeft: `${PREFIX}-coinLeft`,
  coinRight: `${PREFIX}-coinRight`,
};

const StyledRoot = styled('div', { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const Heading = (props: HeadingAllProps) => {
  const { className: classNameProp, title, subtitle, smallScreen, hero } = props;
  const { translate: t } = useLocales();
  const { findPagePath } = usePath();

  const data = useStaticQuery(graphql`
    query {
      lineLeftImage: file(relativePath: { eq: "product/line-l.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 1120)
        }
      }
      lineRightImage: file(relativePath: { eq: "product/line-r.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 1120)
        }
      }
      coinLeft: file(relativePath: { eq: "coin2.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 320)
        }
      }
      coinRight: file(relativePath: { eq: "coin3.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 240)
        }
      }
    }
  `);

  const getTitle = (string: string) => {
    const lastIndex = string.lastIndexOf(' ');
    return {
      text: string.substring(0, lastIndex),
      last: string.split(' ').splice(-1),
    };
  };

  const properTitle = typeof title === 'string' ? getTitle(title) : { text: '', last: '' };

  return (
    <StyledRoot className={clsx(classes.root, classNameProp)}>
      <HeroSection
        classesChildren={{ content: classes.content }}
        background="default"
        spaceBottom={false}
      >
        <BoxWithHeader
          header={
            title ? (
              <>
                {typeof title === 'string' ? (
                  <>
                    {properTitle?.text}{' '}
                    <TextGradient gradientLine="buttonSecondary">{properTitle?.last}</TextGradient>
                  </>
                ) : (
                  title
                )}
              </>
            ) : undefined
          }
          headerProps={{
            variant: 'h2',
            component: 'h1',
          }}
          subheader={subtitle}
          textAlign="center"
          className={classes.text}
        >
          <Button size="large" component={GatsbyLink} to={findPagePath('contact')}>
            {t('pages.product.hero.link')}
          </Button>
        </BoxWithHeader>

        <div className={classes.decorationImg}>
          {hero && (
            <Hero
              heroImg={hero.src.publicURL}
              imageName={hero.src.name}
              alt={hero.alt}
              title={hero?.title || undefined}
            />
          )}

          <GatsbyImage
            image={data.coinLeft.childImageSharp.gatsbyImageData}
            alt=""
            title=""
            className={clsx(classes.coin, classes.coinLeft)}
          />
          <GatsbyImage
            image={data.coinRight.childImageSharp.gatsbyImageData}
            alt=""
            title=""
            className={clsx(classes.coin, classes.coinRight)}
          />
        </div>
      </HeroSection>

      {!smallScreen && (
        <>
          <div className={clsx(classes.lineImage, classes.lineLeftImage)}>
            <GatsbyImage
              image={data.lineLeftImage.childImageSharp.gatsbyImageData}
              alt=""
              title=""
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>

          <div className={clsx(classes.lineImage, classes.lineRightImage)}>
            <GatsbyImage
              image={data.lineRightImage.childImageSharp.gatsbyImageData}
              alt=""
              title=""
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
        </>
      )}
    </StyledRoot>
  );
};

export default Heading;
