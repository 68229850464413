import React from 'react';
import clsx from 'clsx';
// gatsby
import { Link as GatsbyLink } from 'gatsby';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
  Grid,
} from '@mui/material';
// components
import { ContentBox, Hero } from 'components';
// types
import { GatsbyLinkProps } from 'gatsby-link';
import { BoxWithHeaderProps } from 'components/BoxWithHeader/BoxWithHeader';
import { ImageType } from 'types/image';
// hooks
import { useLocales } from 'hooks';
//
import { stylesRoot } from './styles';

interface EcosystemProps {
  header?: string;
  headerProps?: BoxWithHeaderProps['headerProps'];
  subheader?: string;
  subheaderProps?: BoxWithHeaderProps['subheaderProps'];
  url?: GatsbyLinkProps<any>['to'];
  smallScreen?: boolean;
  image?: ImageType;
}

interface EcosystemAllProps extends EcosystemProps {
  className?: string;
}

const PREFIX = 'Ecosystem';
const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  descriptionCard: `${PREFIX}-descriptionCard`,
  descriptionCardContent: `${PREFIX}-descriptionCardContent`,
  diagramCard: `${PREFIX}-diagramCard`,
  diagramImage: `${PREFIX}-diagramImage`,
  diagramImageFixed: `${PREFIX}-diagramImageFixed`,
  header: `${PREFIX}-header`,
  subheader: `${PREFIX}-subheader`,
};

const StyledRoot = styled(ContentBox, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const Ecosystem = (props: EcosystemAllProps) => {
  const {
    className: classNameProp,
    header,
    headerProps,
    subheader,
    subheaderProps,
    url,
    smallScreen,
    image,
  } = props;
  const { translate: t } = useLocales();

  return (
    <StyledRoot
      className={clsx(classes.root, classNameProp)}
      background="transparent"
      spaceSize="huge"
    >
      <Grid container spacing={smallScreen ? 2 : 3}>
        <Grid item xs={12} md={5} lg={4}>
          <Card
            variant="elevation"
            elevation={0}
            classes={{ root: clsx(classes.card, classes.descriptionCard) }}
          >
            <CardContent classes={{ root: classes.descriptionCardContent }}>
              {header && (
                <Typography {...headerProps} className={classes.header}>
                  {header}
                </Typography>
              )}
              {subheader && (
                <Typography {...subheaderProps} className={classes.subheader}>
                  {subheader}
                </Typography>
              )}

              <Typography color="textSecondary">
                {t('pages.product.ecosystem.description')}
              </Typography>

              {url && (
                <Button component={GatsbyLink} size={smallScreen ? 'medium' : 'large'} to={url}>
                  {t('pages.product.ecosystem.btn')}
                </Button>
              )}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={7} lg={8}>
          <Card
            variant="outlined"
            sx={{ textAlign: 'center' }}
            classes={{ root: clsx(classes.card, classes.diagramCard) }}
          >
            <CardHeader
              title={t('pages.product.ecosystem.diagram.title')}
              subheader={t('pages.product.ecosystem.diagram.subtitle')}
              titleTypographyProps={{
                variant: 'h6',
                color: 'primary',
              }}
            />

            <Box className={clsx(classes.diagramImage, smallScreen && classes.diagramImageFixed)}>
              {image && (
                <CardMedia
                  component={Hero}
                  heroImg={image.src.publicURL}
                  imageName={image.src.name}
                  alt={image.alt}
                  title={image?.title}
                />
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

Ecosystem.defaultProps = {
  headerProps: {
    color: 'textPrimary',
    variant: 'h3',
    component: 'h2',
  },
  subheaderProps: {
    color: 'textSecondary',
    variant: 'subtitle1',
    component: 'h4',
  },
} as Partial<EcosystemAllProps>;

export default Ecosystem;
