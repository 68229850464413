import React from 'react';
import clsx from 'clsx';
// gatsby
import { graphql, useStaticQuery } from 'gatsby';
// material
import { styled } from '@mui/material/styles';
// components
import { ContentBox, FeaturedBoxList } from 'components';
// hooks
import { useLocales } from 'hooks';
//
import { stylesRoot } from './styles';

interface TokensProps {
  description?: string;
  smallScreen?: boolean;
}

interface TokensAllProps extends TokensProps {
  className?: string;
}

const PREFIX = 'Tokens';
const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled(ContentBox, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const Tokens = (props: TokensAllProps) => {
  const { className: classNameProp, description, smallScreen } = props;
  const { translate: t } = useLocales();

  const data = useStaticQuery(graphql`
    query {
      utilityImage: file(relativePath: { eq: "product/tokens/utility.png" }) {
        childImageSharp {
          gatsbyImageData(width: 170)
        }
      }
      securityImage: file(relativePath: { eq: "product/tokens/security.png" }) {
        childImageSharp {
          gatsbyImageData(width: 170)
        }
      }
      nftImage: file(relativePath: { eq: "product/tokens/nft.png" }) {
        childImageSharp {
          gatsbyImageData(width: 170)
        }
      }
    }
  `);

  const items = [
    {
      title: t('pages.product.tokens.items.utility.title'),
      description: t('pages.product.tokens.items.utility.description'),
      image: data.utilityImage,
    },
    {
      title: t('pages.product.tokens.items.security.title'),
      description: t('pages.product.tokens.items.security.description'),
      image: data.securityImage,
    },
    {
      title: t('pages.product.tokens.items.nft.title'),
      description: t('pages.product.tokens.items.nft.description'),
      image: data.nftImage,
    },
  ];

  return (
    <StyledRoot className={clsx(classes.root, classNameProp)} background="default">
      <FeaturedBoxList description={description} smallScreen={smallScreen} items={items} />
    </StyledRoot>
  );
};

Tokens.defaultProps = {} as Partial<TokensAllProps>;

export default Tokens;
