import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints, customShadows, spacing } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        position: 'relative',
        [`& .${classes.content}`]: {
          zIndex: 1,
          flexDirection: 'column',
          '& > *:not(:last-child)': {
            marginBottom: spacing(4),
            [breakpoints.up('sm')]: {
              marginBottom: spacing(5.5),
            },
            [breakpoints.up('lg')]: {
              marginBottom: spacing(7),
            },
          },
        },
        [`& .${classes.text}`]: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          flex: 1,
          margin: '0 auto',
          maxWidth: breakpoints.values.sm,
          [breakpoints.up('md')]: {
            maxWidth: breakpoints.values.md,
          },
        },
        [`& .${classes.decorationImg}`]: {
          position: 'relative',
          width: '100%',
          maxWidth: breakpoints.values.sm * 0.75,
          margin: '0 auto',
          [breakpoints.up('md')]: {
            maxWidth: 1034,
            marginBottom: -24,
            '&:before': {
              content: "''",
              display: 'block',
              position: 'absolute',
              zIndex: -1,
              left: '33%',
              right: '33%',
              bottom: 8,
              height: 32,
              margin: 'auto',
              borderRadius: '50%',
              boxShadow: customShadows.z16,
            },
          },
        },
        [`& .${classes.lineImage}`]: {
          display: 'flex',
          flexDirection: 'column-reverse',
          position: 'absolute',
          '& .gatsby-image-wrapper': {
            display: 'block !important',
          },
        },
        [`& .${classes.lineLeftImage}`]: {
          bottom: '0',
          maxHeight: '50%',
          left: 0,
          width: '50%',
          maxWidth: breakpoints.values.sm,
        },
        [`& .${classes.lineRightImage}`]: {
          bottom: 0,
          maxHeight: '55%',
          width: '60%',
          maxWidth: breakpoints.values.sm,
          right: '-12.5%',
        },
        [`& .${classes.coin}`]: {
          position: 'absolute !important' as any,
          '& img': {
            display: 'block',
          },
        },
        [`& .${classes.coinLeft}`]: {
          width: 124,
          left: 0,
          bottom: 0,
          transform: 'translateX(-62%) translateY(40%)',
          [breakpoints.up('md')]: {
            width: 148,
            transform: 'translateX(-62%) translateY(20%)',
          },
        },
        [`& .${classes.coinRight}`]: {
          width: 96,
          right: 0,
          top: 0,
          transform: 'translateX(80%) translateY(10%)',
          [breakpoints.up('md')]: {
            transform: 'translateX(45%) translateY(-50%)',
          },
        },
      },
    },
  };
};
