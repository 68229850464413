import { Theme } from '@mui/material/styles';

export const stylesRoot = (theme: Theme, classes: any) => {
  const { breakpoints } = theme;
  return {
    '&': {
      [`&.${classes.root}`]: {
        position: 'relative',
        [`& .${classes.lineImage}`]: {
          display: 'flex',
          flexDirection: 'column-reverse',
          position: 'absolute',
          top: 0,
          right: breakpoints.values.sm * -0.5,
          maxHeight: '44%',
          width: breakpoints.values.sm,
          [breakpoints.up('xl')]: {
            right: breakpoints.values.sm * -0.25,
          },
          '& .gatsby-image-wrapper': {
            display: 'block !important',
          },
        },
      },
    },
  };
};
