import React from 'react';
import clsx from 'clsx';
// gatsby
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
// material
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
// components
import { BoxWithHeader, CapabilityItem, ContentBox } from 'components';
import {
  Android as AndroidIcon,
  Apple as IOSIcon,
  Monitor as WebBrowserIcon,
} from 'components/CustomIcon';
// types
import { BoxWithHeaderProps } from 'components/BoxWithHeader/BoxWithHeader';
import { CapabilityItemProps } from 'components/CapabilityItem/CapabilityItem';
// hooks
import { useLocales } from 'hooks';
//
import { stylesRoot } from './styles';

interface PlatformsProps {
  header?: string;
  headerProps?: BoxWithHeaderProps['headerProps'];
  description?: string;
  descriptionProps?: BoxWithHeaderProps['subheaderProps'];
  smallScreen?: boolean;
}

interface PlatformsAllProps extends PlatformsProps {
  className?: string;
}

const PREFIX = 'Platforms';
const classes = {
  root: `${PREFIX}-root`,
  lineImage: `${PREFIX}-lineImage`,
};

const StyledRoot = styled(ContentBox, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const Platforms = (props: PlatformsAllProps) => {
  const {
    className: classNameProp,
    header,
    headerProps,
    description,
    descriptionProps,
    smallScreen,
  } = props;
  const { translate: t } = useLocales();

  const data = useStaticQuery(graphql`
    query {
      lineImage: file(relativePath: { eq: "product/line-tr.png" }) {
        name
        childImageSharp {
          gatsbyImageData(width: 1000)
        }
      }
    }
  `);

  const items = [
    {
      title: t('pages.product.platforms.items.web'),
      icon: <WebBrowserIcon />,
      featured: true,
    },
    {
      title: t('pages.product.platforms.items.iOS'),
      icon: <IOSIcon />,
    },
    {
      title: t('pages.product.platforms.items.android'),
      icon: <AndroidIcon />,
    },
  ];

  const renderItem = (item: CapabilityItemProps, val: number) => (
    <Grid key={val} item xs={12} sm={6} md={4}>
      <CapabilityItem
        title={item?.title}
        icon={item?.icon}
        iconSize="large"
        titleTypographyProps={{
          variant: smallScreen ? 'subtitle2' : 'h6',
          component: 'h3',
        }}
        layout="horizontal"
        featured={item?.featured}
      />
    </Grid>
  );

  return (
    <StyledRoot className={clsx(classes.root, classNameProp)} background="primary">
      <BoxWithHeader
        header={header}
        headerProps={headerProps}
        subheader={!smallScreen ? description : undefined}
        subheaderProps={descriptionProps}
        textAlign={smallScreen ? 'center' : 'left'}
      >
        <Grid container spacing={smallScreen ? 2 : 4} justifyContent="center">
          {items.map((item, val) => renderItem(item, val))}
        </Grid>
      </BoxWithHeader>
      {!smallScreen && (
        <div className={classes.lineImage}>
          <GatsbyImage
            image={data.lineImage.childImageSharp.gatsbyImageData}
            alt=""
            title=""
            imgStyle={{ objectFit: 'contain' }}
          />
        </div>
      )}
    </StyledRoot>
  );
};

Platforms.defaultProps = {
  headerProps: {
    color: 'textPrimary',
    variant: 'h2',
    component: 'h2',
  },
  descriptionProps: {
    color: 'primary',
    variant: 'subtitle1',
    component: 'h3',
  },
} as Partial<PlatformsAllProps>;

export default Platforms;
