import React from 'react';
import clsx from 'clsx';
// gatsby
import { graphql, useStaticQuery } from 'gatsby';
// material
import { styled } from '@mui/material/styles';
// components
import { ContentBox, FeaturedBoxList } from 'components';
// hooks
import { useLocales } from 'hooks';
//
import { stylesRoot } from './styles';

interface ActivationProps {
  header?: string;
  subheader?: string;
  description?: string;
  smallScreen?: boolean;
}

interface ActivationAllProps extends ActivationProps {
  className?: string;
}

const PREFIX = 'Activation';
const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled(ContentBox, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const Activation = (props: ActivationAllProps) => {
  const { className: classNameProp, header, subheader, description, smallScreen } = props;
  const { translate: t } = useLocales();

  const data = useStaticQuery(graphql`
    query {
      stakingImage: file(relativePath: { eq: "product/activation/staking.png" }) {
        childImageSharp {
          gatsbyImageData(width: 170)
        }
      }
      farmingAndLendingImage: file(
        relativePath: { eq: "product/activation/farming-and-lending.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 170)
        }
      }
      votingImage: file(relativePath: { eq: "product/activation/voting.png" }) {
        childImageSharp {
          gatsbyImageData(width: 170)
        }
      }
    }
  `);

  const items = [
    {
      title: t('pages.product.activation.items.staking.title'),
      description: t('pages.product.activation.items.staking.description'),
      image: data.stakingImage,
    },
    {
      title: t('pages.product.activation.items.farmingAndLending.title'),
      description: t('pages.product.activation.items.farmingAndLending.description'),
      image: data.farmingAndLendingImage,
    },
    {
      title: t('pages.product.activation.items.voting.title'),
      description: t('pages.product.activation.items.voting.description'),
      image: data.votingImage,
    },
  ];

  return (
    <StyledRoot className={clsx(classes.root, classNameProp)} background="default">
      <FeaturedBoxList
        invertHeader
        header={header}
        headerProps={{ variant: 'h4', component: 'h2' }}
        subheader={subheader}
        subheaderProps={{ variant: 'subtitle1', component: 'h4' }}
        description={description}
        textAlign="center"
        smallScreen={smallScreen}
        items={items}
      />
    </StyledRoot>
  );
};

Activation.defaultProps = {} as Partial<ActivationAllProps>;

export default Activation;
