import React from 'react';
// gatsby
import { graphql } from 'gatsby';
// material
import { Divider, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/material/styles';
// layouts
import { MainLayout } from 'layouts';
// components
import { ContentBox, Logotypes } from 'components';
// pages components
import { ContactBelt } from 'pages-components/home';
import {
  Activation,
  Ecosystem,
  Heading,
  Payments,
  Platforms,
  Tokens,
} from 'pages-components/product';
import { ContactSection, TextAndImage } from 'pages-components/common';
// hooks
import { useLocales, usePath } from 'hooks';

const ProductPage = ({ data: { page }, location, pageContext }: any) => {
  const smallScreen = useMediaQuery(({ breakpoints }: Theme) => breakpoints.down('md'));
  const miniScreen = useMediaQuery(({ breakpoints }: Theme) => breakpoints.down('sm'));
  const { translate: t } = useLocales();
  const { findPagePath } = usePath();

  const { frontmatter: frontMatter } = page;
  const {
    activation,
    api,
    assets,
    bonuses,
    crmAndBackOffice,
    ecosystem,
    investorPanel,
    listing,
    multiBlockchain,
    nftTokens,
    paymentCard,
    payments,
    platforms,
    secureTransactions,
    tokens,
  } = frontMatter.sections;

  return (
    <MainLayout
      metadata={frontMatter?.metadata}
      contact={false}
      path={location.pathname}
      locale={pageContext.locale}
    >
      {frontMatter?.heading && (
        <Heading
          title={frontMatter?.heading?.title}
          subtitle={frontMatter?.heading?.subtitle}
          smallScreen={smallScreen}
          hero={frontMatter.heading.image}
        />
      )}
      {ecosystem?.show && (
        <Ecosystem
          header={t('pages.product.ecosystem.title')}
          subheader={t('pages.product.ecosystem.subtitle')}
          url={findPagePath('contact')}
          smallScreen={miniScreen}
          image={ecosystem?.image}
        />
      )}
      {tokens?.show && <Tokens description={t('pages.product.tokens.description')} />}
      {(multiBlockchain?.show || investorPanel?.show) && (
        <ContentBox horizontalPadding={false}>
          {multiBlockchain?.show && (
            <TextAndImage
              header={t('pages.product.multiBlockchain.title')}
              description={t('pages.product.multiBlockchain.subtitle')}
              content={
                <>
                  <Typography>
                    {t('pages.product.multiBlockchain.description.paragraph1')}
                  </Typography>
                  <Typography>
                    {t('pages.product.multiBlockchain.description.paragraph2')}
                  </Typography>
                </>
              }
              image={multiBlockchain?.image}
              revertColumns={!smallScreen}
            />
          )}

          {investorPanel?.show && (
            <TextAndImage
              header={t('pages.product.investorPanel.title')}
              description={t('pages.product.investorPanel.subtitle')}
              content={<Typography>{t('pages.product.investorPanel.description')}</Typography>}
              image={investorPanel?.image}
            />
          )}
        </ContentBox>
      )}
      {platforms?.show && (
        <Platforms
          header={t('pages.product.platforms.title')}
          description={t('pages.product.platforms.subtitle')}
          smallScreen={smallScreen}
        />
      )}
      <ContactBelt />
      <Divider />
      {crmAndBackOffice?.show && (
        <TextAndImage
          header={t('pages.product.crmAndBackOffice.title')}
          description={t('pages.product.crmAndBackOffice.subtitle')}
          content={<Typography>{t('pages.product.crmAndBackOffice.description')}</Typography>}
          image={crmAndBackOffice?.image}
          revertColumns={!smallScreen}
          spaceSize="huge"
        />
      )}
      {activation?.show && (
        <Activation
          header={t('pages.product.activation.title')}
          subheader={t('pages.product.activation.subtitle')}
          description={t('pages.product.activation.description')}
        />
      )}
      {(listing?.show || secureTransactions?.show) && (
        <ContentBox horizontalPadding={false} spaceBottom={false}>
          {listing?.show && (
            <TextAndImage
              header={t('pages.product.listing.title')}
              description={t('pages.product.listing.subtitle')}
              content={
                <>
                  <Typography>{t('pages.product.listing.description.paragraph1')}</Typography>
                  <Typography>
                    {t('pages.product.multiBlockchain.description.paragraph2')}
                  </Typography>

                  {listing?.logos && (
                    <Logotypes images={listing.logos} gap={4} colsXs={2} sx={{ maxWidth: 320 }} />
                  )}
                </>
              }
              image={listing?.image}
            />
          )}

          {secureTransactions?.show && (
            <TextAndImage
              header={t('pages.product.secureTransactions.title')}
              description={t('pages.product.secureTransactions.subtitle')}
              content={<Typography>{t('pages.product.secureTransactions.description')}</Typography>}
              image={secureTransactions?.image}
              revertColumns={!smallScreen}
            />
          )}
        </ContentBox>
      )}
      {payments?.show && (
        <Payments
          header={t('pages.product.payments.title')}
          description={t('pages.product.payments.subtitle')}
          images={payments?.logos}
          smallScreen={smallScreen}
        />
      )}
      <ContentBox horizontalPadding={false} spaceTop={false}>
        {assets?.show && (
          <TextAndImage
            header={t('pages.product.assets.title')}
            description={t('pages.product.assets.subtitle')}
            content={<Typography>{t('pages.product.assets.description')}</Typography>}
            image={assets?.image}
          />
        )}
        {bonuses?.show && (
          <TextAndImage
            header={t('pages.product.bonuses.title')}
            description={t('pages.product.bonuses.subtitle')}
            content={<Typography>{t('pages.product.bonuses.description')}</Typography>}
            image={bonuses?.image}
            revertColumns={!smallScreen}
          />
        )}
        {nftTokens?.show && (
          <TextAndImage
            header={t('pages.product.nftTokens.title')}
            description={t('pages.product.nftTokens.subtitle')}
            content={<Typography>{t('pages.product.nftTokens.description')}</Typography>}
            image={nftTokens?.image}
          />
        )}
        {paymentCard?.show && (
          <TextAndImage
            header={t('pages.product.paymentCard.title')}
            description={t('pages.product.paymentCard.subtitle')}
            content={<Typography>{t('pages.product.paymentCard.description')}</Typography>}
            image={paymentCard?.image}
            revertColumns={!smallScreen}
          />
        )}
        {api?.show && (
          <TextAndImage
            header={t('pages.product.api.title')}
            description={t('pages.product.api.subtitle')}
            content={<Typography>{t('pages.product.api.description')}</Typography>}
            image={api?.image}
          />
        )}
      </ContentBox>
      <ContactSection variant="contact" />
    </MainLayout>
  );
};

export default ProductPage;

export const pageQuery = graphql`
  query ($id: String!) {
    page: mdx(id: { eq: $id }) {
      frontmatter {
        title
        slug
        metadata {
          title
          description
          og_title
          og_description
          og_type
          og_image {
            publicURL
            name
          }
          fb_app_id
          twitter_card
          twitter_creator
          twitter_title
          twitter_description
          twitter_image {
            publicURL
            name
          }
          twitter_url
          keywords
        }
        heading {
          title
          subtitle
          image {
            src {
              publicURL
              name
            }
            title
            alt
          }
        }
        sections {
          activation {
            show
          }
          ecosystem {
            show
            linkTo
            image {
              src {
                publicURL
                name
              }
              title
              alt
            }
          }
          multiBlockchain {
            show
            image {
              src {
                publicURL
                name
              }
              title
              alt
            }
          }
          investorPanel {
            show
            image {
              src {
                publicURL
                name
              }
              title
              alt
            }
          }
          crmAndBackOffice {
            show
            image {
              src {
                publicURL
                name
              }
              title
              alt
            }
          }
          platforms {
            show
          }
          tokens {
            show
          }
          listing {
            show
            image {
              src {
                publicURL
                name
              }
              title
              alt
            }
            logos {
              src {
                publicURL
                name
              }
              title
              alt
            }
          }
          secureTransactions {
            show
            image {
              src {
                publicURL
                name
              }
              title
              alt
            }
          }
          payments {
            show
            logos {
              src {
                publicURL
                name
              }
              title
              alt
            }
          }
          assets {
            show
            image {
              src {
                publicURL
                name
              }
              title
              alt
            }
          }
          bonuses {
            show
            image {
              src {
                publicURL
                name
              }
              title
              alt
            }
          }
          nftTokens {
            show
            image {
              src {
                publicURL
                name
              }
              title
              alt
            }
          }
          paymentCard {
            show
            image {
              src {
                publicURL
                name
              }
              title
              alt
            }
          }
          api {
            show
            image {
              src {
                publicURL
                name
              }
              title
              alt
            }
          }
        }
      }
    }
  }
`;
