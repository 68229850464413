import React from 'react';
import clsx from 'clsx';
// material
import { styled } from '@mui/material/styles';
// components
import { BoxWithHeader, ContentBox, Logotypes } from 'components';
// types
import { BoxWithHeaderProps } from 'components/BoxWithHeader/BoxWithHeader';
import { ImageTypeArray } from 'types/image';
//
import { stylesRoot } from './styles';

interface PaymentsProps {
  header?: string;
  headerProps?: BoxWithHeaderProps['headerProps'];
  description?: string;
  descriptionProps?: BoxWithHeaderProps['subheaderProps'];
  images?: ImageTypeArray;
  smallScreen?: boolean;
}

interface PaymentsAllProps extends PaymentsProps {
  className?: string;
}

const PREFIX = 'Payments';
const classes = {
  root: `${PREFIX}-root`,
};

const StyledRoot = styled(ContentBox, { name: PREFIX, slot: 'root' })(({ theme }) =>
  stylesRoot(theme, classes),
);

const Payments = (props: PaymentsAllProps) => {
  const {
    className: classNameProp,
    header,
    headerProps,
    description,
    descriptionProps,
    images,
    smallScreen,
  } = props;

  return (
    <StyledRoot className={clsx(classes.root, classNameProp)}>
      <BoxWithHeader
        header={header}
        headerProps={headerProps}
        subheader={!smallScreen ? description : undefined}
        subheaderProps={descriptionProps}
        textAlign="center"
      >
        {images && (
          <Logotypes images={images} gap={smallScreen ? 2 : 4} colsXs={3} colsSm={4} colsMd={7} />
        )}
      </BoxWithHeader>
    </StyledRoot>
  );
};

Payments.defaultProps = {
  headerProps: {
    color: 'textPrimary',
    variant: 'h4',
    component: 'h2',
  },
  descriptionProps: {
    color: 'primary',
    variant: 'subtitle1',
    component: 'h3',
  },
} as Partial<PaymentsAllProps>;

export default Payments;
